.content {
  display: block;
  width: 800px;
  padding: 10px;
}

h2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

h2 img {
  width: 80px;
  height: 80px;
  margin-right: 10px;
  border-radius: 20px;
}

.last_updated {
  color: #777777;
  font-weight: normal;
  text-align: center;
}

.tables_container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.tables_container > .first_column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-right: 15px;
  flex-basis: 0;
  flex-grow: 1;
}

.tables_container > .second_column {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  flex-basis: 0;
  flex-grow: 1;
}

table {
  border-collapse: collapse;
  border-style: solid;
  width: 100%;
  border-radius: 1em;
  overflow: hidden;
  border-color: none;
}

th {
  font-size: 0.8rem;
  padding: 8px;
  text-align: center;
  background: #f3f3f3;
  border-bottom: 4px solid white;
  color: gray;
}

td {
  padding: 8px;
  text-align: left;
  background: #f3f3f3;
  border-bottom: 2px solid white; 
}

td:first-child {
  padding-left: 25px;
}

td:last-child {
  padding-right: 25px;
}

.source_symbol {
  font-weight: 600;
}

.currency_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.currency_container .first_column {
  margin-right: 10px;
}

.currency_container .second_column {
  display: flex;
  flex-direction: column;
}

.source_name {
  font-size: 0.8em;
  color: #777777;
}

.rate_container {
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: center;
  line-height: 1em;
}

.change_container {
  padding-left: 10px;
}

.rate_container .rate {
  font-weight: 600;
  align-self: flex-end;
}

.rate_container .symbol {
  color: #777777;
  align-self: flex-end;
  font-size: 0.8em;
}

.change_container .change {
  color: white;
  align-self: flex-end;
  background-color: gray;
  padding: 5px;
  border-radius: 5px;
  font-size: 0.7em;
  display: block;
  text-align: center;
}

.change_container .change.up {
  background-color: rgb(38, 149, 38);
}

.change_container .change.down {
  background-color: rgb(255, 41, 41);
}

.notice {
  font-size: 0.7em;
  padding: 20px;
  color: #777777;
  text-align: center;
}

.notice p:first-child {
  font-weight: 700;
}

.scan_text {
  font-weight: 600;
  font-size: 0.7em;
  color: #777777;
  text-align: center;
}